import React, { useState } from "react";
import { monthNames } from "../../assets/data/dataArray";

const InputTextAreaDiv =
  " textarea max-w-[320px] textarea-bordered h-[300px] w-full rounded text-black";
export const TextInputField1 = ({
  label,
  labelColor,
  value,
  onChange,
  placeholder,
  name,
  type,
  style,
  warning,
  warningText,
  width,
  inputStyle,
  disabled,
}) => {
  return (
    <div
      className={"form-control  "}
      style={{ width: `${width ? width : 320}px`, ...style }}
    >
      <div className="text-[13px] font-medium text-gray-100">
        <p className="" style={{ color: labelColor }}>
          {label}
        </p>
      </div>
      <input
        type={type || "text"}
        name={name}
        placeholder={placeholder !== undefined ? placeholder : "Type"}
        className={
          "text-white bg-dark border rounded-full px-4 py-2.5 text-[15px] w-full mt-2 mb-3"
        }
        style={{ borderColor: warning ? "#ef4444" : "#6b7280", ...inputStyle }}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />

      {warning && (
        <div className="flex gap-1 mb-2 text-red-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
            />
          </svg>

          <p className="text-[13px] ">{warningText}</p>
        </div>
      )}
    </div>
  );
};
export const TextInputFieldWithSecondaryLabel = ({
  label,
  label2,
  value,
  onChange,
  placeholder,
  name,
  type,
  style,
  warning,
  warningText,
  width,
}) => {
  return (
    <div
      className={"form-control  "}
      style={{ width: `${width ? width : 320}px`, ...style }}
    >
      <div className="text-[13px] font-medium text-gray-100">
        <p className="font-bold">{label}</p>
        <p className="mt-1 text-gray-400">{label2}</p>
      </div>
      <input
        type={type || "text"}
        name={name}
        placeholder={placeholder || ""}
        className={
          "text-white bg-dark border  rounded px-4 py-2.5 font-semibold text-[15px] w-full mt-2 mb-3"
        }
        style={{ borderColor: warning ? "#ef4444" : "#6b7280" }}
        value={value}
        onChange={onChange}
      />

      {warning && (
        <div className="flex gap-1 mb-2 text-red-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
            />
          </svg>

          <p className="text-[13px] ">{warningText}</p>
        </div>
      )}
    </div>
  );
};

export const PasswordInputField1 = ({
  labelStyle,
  label,
  value,
  onChange,
  placeholder,
  name,
  type,
  style,
  warning,
  warningText,
  width,
}) => {
  const [isHidden, setHidden] = useState(true);
  return (
    <div
      className={"form-control  "}
      style={{ width: `${width ? width : 320}px`, ...style }}
    >
      <div className="text-[13px] font-medium text-gray-100">
        <p className="font-bold" style={{ ...labelStyle }}>
          {label}
        </p>
      </div>
      <div className="relative">
        <input
          type={isHidden ? "password" : "text"}
          name={name}
          placeholder={placeholder || "Password"}
          className={
            "text-white bg-dark border rounded-full px-4 py-2.5 font-semibold text-[15px] w-full mt-2 mb-2"
          }
          style={{ borderColor: warning ? "#ef4444" : "#6b7280" }}
          value={value}
          onChange={onChange}
        />

        <button
          onClick={() => setHidden((prevState) => !prevState)}
          className="absolute top-[18px] right-[10px]"
        >
          {!isHidden ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="size-5"
            >
              <path d="M10 12.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
              <path
                fillRule="evenodd"
                d="M.664 10.59a1.651 1.651 0 0 1 0-1.186A10.004 10.004 0 0 1 10 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0 1 10 17c-4.257 0-7.893-2.66-9.336-6.41ZM14 10a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="size-5"
            >
              <path
                fillRule="evenodd"
                d="M3.28 2.22a.75.75 0 0 0-1.06 1.06l14.5 14.5a.75.75 0 1 0 1.06-1.06l-1.745-1.745a10.029 10.029 0 0 0 3.3-4.38 1.651 1.651 0 0 0 0-1.185A10.004 10.004 0 0 0 9.999 3a9.956 9.956 0 0 0-4.744 1.194L3.28 2.22ZM7.752 6.69l1.092 1.092a2.5 2.5 0 0 1 3.374 3.373l1.091 1.092a4 4 0 0 0-5.557-5.557Z"
                clipRule="evenodd"
              />
              <path d="m10.748 13.93 2.523 2.523a9.987 9.987 0 0 1-3.27.547c-4.258 0-7.894-2.66-9.337-6.41a1.651 1.651 0 0 1 0-1.186A10.007 10.007 0 0 1 2.839 6.02L6.07 9.252a4 4 0 0 0 4.678 4.678Z" />
            </svg>
          )}
        </button>
      </div>
      {warning && (
        <div className="flex gap-1 mb-2 text-red-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
            />
          </svg>

          <p className="text-[13px] ">{warningText}</p>
        </div>
      )}
    </div>
  );
};

export const PasswordInputFieldWithCondotion = ({
  label,
  value,
  onChange,
  placeholder,
  name,
  type,
  style,
  labelStyle,
  con1,
  con2,
  con3,
  warning,
  warningText,
  width,
}) => {
  const [isHidden, setHidden] = useState(true);
  return (
    <div
      className={"form-control  "}
      style={{ width: `${width ? width : 320}px`, ...style }}
    >
      <div className="text-[13px] font-medium text-gray-100">
        <p className="font-bold" style={{ ...labelStyle }}>
          {label}
        </p>
      </div>
      <div className="relative">
        <input
          type={isHidden ? "password" : "text"}
          name={name}
          placeholder={placeholder || "Password"}
          className={
            "text-white bg-dark border rounded px-4 py-2.5 font-semibold text-[15px] w-full mt-2 mb-2"
          }
          style={{ borderColor: con1 && con2 && con3 ? "#6b7280" : "#ef4444" }}
          value={value}
          onChange={onChange}
        />

        <button
          onClick={() => setHidden((prevState) => !prevState)}
          className="absolute top-[18px] right-[10px]"
        >
          {!isHidden ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="size-5"
            >
              <path d="M10 12.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
              <path
                fillRule="evenodd"
                d="M.664 10.59a1.651 1.651 0 0 1 0-1.186A10.004 10.004 0 0 1 10 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0 1 10 17c-4.257 0-7.893-2.66-9.336-6.41ZM14 10a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="size-5"
            >
              <path
                fillRule="evenodd"
                d="M3.28 2.22a.75.75 0 0 0-1.06 1.06l14.5 14.5a.75.75 0 1 0 1.06-1.06l-1.745-1.745a10.029 10.029 0 0 0 3.3-4.38 1.651 1.651 0 0 0 0-1.185A10.004 10.004 0 0 0 9.999 3a9.956 9.956 0 0 0-4.744 1.194L3.28 2.22ZM7.752 6.69l1.092 1.092a2.5 2.5 0 0 1 3.374 3.373l1.091 1.092a4 4 0 0 0-5.557-5.557Z"
                clipRule="evenodd"
              />
              <path d="m10.748 13.93 2.523 2.523a9.987 9.987 0 0 1-3.27.547c-4.258 0-7.894-2.66-9.337-6.41a1.651 1.651 0 0 1 0-1.186A10.007 10.007 0 0 1 2.839 6.02L6.07 9.252a4 4 0 0 0 4.678 4.678Z" />
            </svg>
          )}
        </button>
      </div>
      {warning && (
        <div className="flex gap-1 mb-2 text-red-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
            />
          </svg>

          <p className="text-[13px] ">{warningText}</p>
        </div>
      )}

      <div className="mt-3 text-[14px]">
        <p> Your password must contain</p>
        <div className="flex items-center gap-1 mt-2">
          {con1 ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="green"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="red"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          )}
          <p>Uppercase & Lowercase character</p>
        </div>
        <div className="flex items-center gap-1 mt-2">
          {con2 ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="green"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="red"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          )}
          <p>Number or special character (i.e: # ? ! &)</p>
        </div>
        <div className="flex items-center gap-1 mt-2">
          {con3 ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="green"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="red"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          )}
          <p>Min 10 characters</p>
        </div>
      </div>
    </div>
  );
};

export const DateOfBirth = ({
  label,
  label2,
  value_dd,
  value_mm,
  value_yy,
  onChange,
  placeholder_dd,
  placeholder_mm,
  placeholder_yy,
  name_dd,
  name_mm,
  name_yy,
  type,
  style,
  warning_dd,
  warning_mm,
  warning_yy,
  warningText_dd,
  warningText_mm,
  warningText_yy,
  width,
}) => {
  return (
    <div
      className={"form-control  "}
      style={{ width: `${width ? width : 320}px`, ...style }}
    >
      <div className="text-[13px] font-medium text-gray-100">
        <p className="font-bold">{label}</p>
        <p className="mt-1 text-gray-400">{label2}</p>
      </div>
      <div className="flex items-center gap-2">
        <input
          type={type || "text"}
          name={name_dd}
          placeholder={placeholder_dd || ""}
          className={
            "text-white bg-dark border  rounded px-4 py-2.5 font-semibold text-[15px] w-3/12 mt-2 mb-3"
          }
          style={{ borderColor: warning_dd ? "#ef4444" : "#6b7280" }}
          value={value_dd}
          onChange={onChange}
        />
        {/* <input
          type={type || "text"}
          name={name_mm}
          placeholder={placeholder_mm || ""}
          className={
            "text-white bg-dark border  rounded px-4 py-2.5 font-semibold text-[15px] w-5/12 mt-2 mb-3"
          }
          style={{ borderColor: warning_mm ? "#ef4444" : "#6b7280" }}
          value={value_mm}
          onChange={onChange}
        /> */}

        <SelectInputCustom
          name={name_mm}
          onChange={onChange}
          value={value_mm}
          options={monthNames}
          classNames={"text-[15px] w-5/12 mt-3 mb-3"}
          style={{ borderColor: warning_mm ? "#ef4444" : "#6b7280" }}
        />

        <input
          type={type || "text"}
          name={name_yy}
          placeholder={placeholder_yy || ""}
          className={
            "text-white bg-dark border  rounded px-4 py-2.5 font-semibold text-[15px] w-4/12 mt-2 mb-3"
          }
          style={{ borderColor: warning_yy ? "#ef4444" : "#6b7280" }}
          value={value_yy}
          onChange={onChange}
        />
      </div>
      {warning_dd && (
        <div className="flex gap-1 mb-2 text-red-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
            />
          </svg>

          <p className="text-[13px] ">{warningText_dd}</p>
        </div>
      )}
      {warning_mm && (
        <div className="flex gap-1 mb-2 text-red-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
            />
          </svg>

          <p className="text-[13px] ">{warningText_mm}</p>
        </div>
      )}
      {warning_yy && (
        <div className="flex gap-1 mb-2 text-red-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
            />
          </svg>

          <p className="text-[13px] ">{warningText_yy}</p>
        </div>
      )}
    </div>
  );
};

export const RadioInputFieldWithSecondaryLabel = ({
  label,
  label2,
  value,
  onChange,
  placeholder,
  name,
  type,
  style,
  warning,
  warningText,
  options,
  isOptionsHorizontal,
  width,
}) => {
  return (
    <div
      className={"form-control  "}
      style={{ width: `${width ? width : 320}px`, ...style }}
    >
      <div className="text-[13px] font-medium text-gray-100">
        <p className="font-bold">{label}</p>
        <p className="mt-1 text-gray-200">{label2}</p>
      </div>

      <div
        className={
          isOptionsHorizontal
            ? "flex flex-wrap gap-3 mt-3"
            : "flex flex-col flex-wrap gap-3 mt-3"
        }
      >
        {options?.map((op, index) => {
          let isChecked = value === op?.value ? true : false;
          console.log(isChecked);

          return (
            <div key={index} className="flex items-center  gap-0 ">
              <input
                type="radio"
                id={op?.id}
                name={name}
                value={op?.value}
                checked={isChecked}
                onChange={onChange}
                disabled={op?.isDisabled}
              />
              <div
                className={
                  op?.isDisabled
                    ? "mt-2.5 text-gray-600 max-w-[290px]"
                    : "mt-2.5  max-w-[290px]"
                }
              >
                <p>{op?.label}</p>
                {op?.msg && (
                  <p
                    className={
                      op?.isDisabled
                        ? "text-[10px] text-gray-700"
                        : "text-[10px] text-gray-300"
                    }
                  >
                    {op?.msg}
                  </p>
                )}
              </div>
            </div>
          );
        })}
      </div>

      {warning && (
        <div className="flex gap-1 mb-2 text-red-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
            />
          </svg>

          <p className="text-[13px] ">{warningText}</p>
        </div>
      )}
    </div>
  );
};

export const PolicyCheckout = ({
  label,
  value,
  onChange,
  name,
  style,
  textColor,
  width,
}) => {
  return (
    <div
      className={"form-control  "}
      style={{ width: `${width ? width : 320}px`, ...style }}
    >
      <div className="flex gap-3 p-4 rounded bg-primary">
        <div className="flex items-start gap-3 ">
          <input
            type="checkbox"
            name={name}
            onChange={onChange}
            checked={value}
            className="mt-1.5"
          />
          <p className="" style={{ color: textColor || "black" }}>
            {label}
          </p>
        </div>
      </div>
    </div>
  );
};
export const PolicyCheckoutNoBackground = ({
  label,
  value,
  onChange,
  name,
  style,
  textColor,
  width,
}) => {
  return (
    <div
      className={"form-control  "}
      style={{ width: `${width ? width : 320}px`, ...style }}
    >
      <div className="flex gap-3 p-4 rounded ">
        <div className="flex items-start gap-3 ">
          <input
            type="checkbox"
            name={name}
            onChange={onChange}
            checked={value}
            className="mt-1.5"
          />
          <p className="" style={{ color: textColor || "white" }}>
            {label}
          </p>
        </div>
      </div>
    </div>
  );
};
export const CheckBoxOnly = ({
  value,
  onChange,
  name,
  style,
  textColor,
  width,
}) => {
  return (
    <div className={"form-control  "} style={{ ...style }}>
      <div className="flex gap-3 p-4 rounded ">
        <div className="flex items-start gap-3 ">
          <input
            type="checkbox"
            name={name}
            onChange={onChange}
            checked={value}
            className="mt-1.5"
          />
        </div>
      </div>
    </div>
  );
};

// UPLOAD

export const UploadTextInputField = ({
  label,
  value,
  onChange,
  placeholder,
  name,
  type,
  style,
  warning,
  warningText,
  secondaryLabel,
  width,
}) => {
  return (
    <div
      className={"form-control w-[420px] mb-3"}
      style={{ width: `${width ? width : 420}px`, ...style }}
    >
      <div className="text-[13px] font-medium text-gray-100">
        <p className="font-bold">{label}</p>
      </div>
      {secondaryLabel && (
        <div className="text-[10px] font-medium text-gray-400">
          <p className="font-bold">{secondaryLabel}</p>
        </div>
      )}
      <input
        type={type || "text"}
        name={name}
        placeholder={placeholder || placeholder == "" ? placeholder : "Type"}
        className={
          "text-white bg-dark border  rounded-full px-4 py-2.5 font-semibold text-[15px] w-full mt-2 "
        }
        style={{ borderColor: warning ? "#ef4444" : "#6b7280" }}
        value={value}
        onChange={onChange}
      />

      {warning && (
        <div className="flex gap-1 mb-2 text-red-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
            />
          </svg>

          <p className="text-[13px] ">{warningText}</p>
        </div>
      )}
    </div>
  );
};
export const UploadTextAreaField = ({
  label,
  value,
  onChange,
  placeholder,
  name,
  type,
  style,
  warning,
  warningText,
  secondaryLabel,
  boxHeight,
  width,
}) => {
  return (
    <div
      className={"form-control "}
      style={{ width: `${width ? width : 420}px`, ...style }}
    >
      <div className="text-[13px] font-medium text-gray-100">
        <p className="font-bold">{label}</p>
      </div>
      {secondaryLabel && (
        <div className="text-[10px] font-medium text-gray-400">
          <p className="font-bold">{secondaryLabel}</p>
        </div>
      )}
      <textarea
        type={type || "text"}
        name={name}
        placeholder={placeholder || "Type"}
        className={
          "text-white bg-dark border  rounded px-4 py-2.5 font-semibold text-[15px] w-full mt-2 mb-3"
        }
        style={{
          borderColor: warning ? "#ef4444" : "#6b7280",
          height: boxHeight || "150px",
        }}
        value={value}
        onChange={onChange}
      />

      {warning && (
        <div className="flex gap-1 mb-2 text-red-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
            />
          </svg>

          <p className="text-[13px] ">{warningText}</p>
        </div>
      )}
    </div>
  );
};

export const SelectTrackCustom = ({
  value,
  onChange,
  label,
  options,
  errorText,
  name,
  defaultDisabledValue,
  style,
  width,
  classNames,
}) => {
  const [query, setQuery] = useState(""); // State for search query

  // Filter options based on search query
  const filteredOptions = options?.filter((option) =>
    option.title.toLowerCase().includes(query.toLowerCase())
  );
  return (
    <div
      className={classNames ? classNames : "form-control mb-3"}
      style={{ width: `${width ? width : 420}px`, ...style }}
    >
      <div className="text-[13px] font-medium text-gray-100">
        <p className="font-bold">{label}</p>
      </div>
      <div className="bg-dark border py-0.5 rounded-full px-0 w-full mt-2 mb-3">
        {/* Input for searching */}
        <input
          type="text"
          placeholder="Type to search..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          className="text-white bg-dark border-none outline-none w-full py-2 px-4 mb-2 rounded-full"
        />
        <select
          name={name}
          className="text-white bg-dark rounded-full font-semibold text-[15px] w-full py-2.5 px-4"
          value={value}
          onChange={onChange}
        >
          <option defaultValue="" className="">
            {defaultDisabledValue ? defaultDisabledValue : "Select"}
          </option>

          {filteredOptions?.map((option) => (
            <option key={option.value} value={option.value}>
              <div className="flex gap-2 my-1 items-center hover:bg-[#1F1F1F] rounded-lg px-3 py-1 cursor-pointer">
                {option.img && <img src={option.img} className="h-[35px]" />}
                <div>
                  <p className="text-[14px]">{option.title.slice(0, 35)}...</p>
                  <p className="text-[10px] text-gray-400">
                    {option?.user?.name} - {option?.genre}
                  </p>
                </div>
              </div>
            </option>
          ))}
        </select>
      </div>

      <label className="label">
        <span className="text-red-500 font-semibold text-[14px]">
          {errorText}
        </span>
      </label>
    </div>
  );
};

export const SelectInputCustom = ({
  value,
  onChange,
  label,
  options,
  errorText,
  name,
  defaultDisabledValue,
  style,
  width,
  classNames,
}) => {
  return (
    <div
      className={classNames ? classNames : "form-control mb-3"}
      style={{ width: `${width ? width : 420}px`, ...style }}
    >
      <div className="text-[13px] font-medium text-gray-100 ">
        <p className="font-bold">{label}</p>
      </div>
      <div
        className={" bg-dark border py-0.5 rounded-full px-0 w-full mt-2 mb-3"}
      >
        <select
          name={name}
          className={
            "text-white bg-dark rounded-full font-semibold text-[15px] w-full py-2.5 px-4"
          }
          value={value}
          onChange={onChange}
        >
          <option defaultValue={""} className="">
            {defaultDisabledValue ? defaultDisabledValue : "Select"}
          </option>

          {options?.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      <label className="label">
        <span className="text-red-500 font-semibold text-[14px]">
          {errorText}
        </span>
      </label>
    </div>
  );
};

export const SelectInputSongsDetailsCustom = ({
  value,
  onChange,
  label,
  options,
  errorText,
  name,
  defaultDisabledValue,
  style,
  width,
  classNames,
}) => {
  return (
    <div
      className={classNames ? classNames : "form-control "}
      style={{ width: `${width ? width : 420}px`, ...style }}
    >
      <div
        className={
          "border border-primary bg-transparent  py-0 rounded px-0 w-full "
        }
      >
        <select
          name={name}
          className={
            "text-primary bg-transparent  text-[15px] w-full py-1 px-4"
          }
          value={value}
          onChange={onChange}
        >
          <option disabled defaultValue={""} className="">
            {defaultDisabledValue ? defaultDisabledValue : "Select"}
          </option>

          {options?.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      <label className="label">
        <span className="text-red-500 font-semibold text-[14px]">
          {errorText}
        </span>
      </label>
    </div>
  );
};
