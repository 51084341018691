import React, { useEffect, useState } from "react";
import { logopngw } from "../assets/logo";
import { BtnFilled, BtnOutline, DivBtnFilled } from "../component/button";
import {
  PasswordInputField1,
  RadioInputFieldWithSecondaryLabel,
  SelectInputCustom,
  TextInputField1,
  UploadTextAreaField,
  UploadTextInputField,
} from "../component/form/InputField";
import { TagsInput } from "react-tag-input-component";
import Navbar from "../component/shared/Navbar";
import {
  closeCrossWhite,
  fileUpload,
  musicFileWhite,
} from "../assets/iconsSvg";
import toast from "react-hot-toast";
import axios from "axios";
import axiosInstance from "../redux/helpers/axios";
import { useDispatch, useSelector } from "react-redux";
import { dot_gif } from "../assets/images";
import { uploadSong } from "../redux/actions";
import { useNavigate } from "react-router-dom";
import { store } from "../redux";
import { songConstant } from "../redux/actions/constants";
import { parseBlob } from "music-metadata";

const generateLinkFromString = (str) => {
  return str?.split("")?.length > 0
    ? `${str?.toLowerCase()?.replace(/\s+/g, "-")}`
    : ""; // Replace spaces (or multiple spaces) with hyphens
};

let privacyOptions = [
  {
    id: "Public",
    label: "Public",
    value: "Public",
    msg: "Anyone will be able to listen to this track.",
    isDisabled: false,
  },
  {
    id: "Private",
    label: "Private",
    value: "Private",
    msg: "Only you will be able to listen to this track.",
    isDisabled: false,
  },
  {
    id: "Scheduled",
    label: "Scheduled",
    value: "Scheduled",
    msg: "Anyone will be able to listen to this track.",
    isDisabled: true,
  },
];

let detailsBtnNames = [
  {
    id: 0,
    name: "Basic Info",
    tag: "",
    isDisabled: false,
  },
  {
    id: 1,
    name: "Metadata",
    tag: "",
    isDisabled: false,
  },
  {
    id: 2,
    name: "Permissions",
    tag: "",
    isDisabled: false,
  },
  {
    id: 3,
    name: "Advanced",
    tag: "New",
    isDisabled: true,
  },
];

let genreOptions = [
  "None",
  "Indie Rock",
  "Afro House",
  "Disco",
  "Deep House",
  "Custom",
  "Alternative Rock",
  "Ambient",
  "Classical",
  "Country",
  "Dance & EDM",
  "Dancehall",
  "Drum & Bass",
  "Dubstep",
  "Electronica",
  "Folk & Singer-Songwriter",
  "Soul",
  "Jackin House",
];

let yesNoOptions = ["Yes", "No"];

let licenseOptions = [
  {
    id: "All Rights Reserved",
    label: "All Rights Reserved",
    value: "All Rights Reserved",
    isDisabled: false,
  },
  {
    id: "Creative Commons",
    label: "Creative Commons",
    value: "Creative Commons",
    isDisabled: false,
  },
];

const Divider = () => {
  return <div className="border-t-[0.45px] border-t-gray-600 w-8/12 my-11" />;
};

const Upload = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [detailsBtnActiveNo, setdetailsBtnActiveNo] = useState(0);
  const [isTitleWrote, setTitleWrote] = useState(false);
  const [isPermalinkAvailable, setPermalinkAvailable] = useState(false);
  const [previw, setPreview] = useState("");
  const [track, setTrack] = useState(null);
  const [trackURL, setTrackURL] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [music, setMusic] = useState("");
  const [artist, setArtist] = useState("");
  const [musicList, setMusicList] = useState([]);

  const [formData, setFormData] = useState({
    title: "",
    desc: "",
    genre: "",
    track: "",
    musicList: [],
    tags: [],
    img: "",
    privacyStatus: "Public",
    permalink: "",
    caption: "",

    containsMusic: "No",
    artist: "",
    publisher: "",
    isrc: "",
    composer: "",
    releaseTitle: "",
    buyLink: "",
    albumTitle: "",
    recordLabel: "",
    releaseDate: "",
    barcode: "",
    iswc: "",
    pLine: "",
    explicit: "",
    license: "All Rights Reserved",
    offline: false,
    download: false,
  });

  const auth = useSelector((state) => state.auth);
  const song = useSelector((state) => state.song);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "title") setTitleWrote((prevState) => true);
    if (name === "peramalink") setPermalinkAvailable((prevState) => true);
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setPreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
      setPreview(reader.result);
    }
  };

  const handleMetadataImage = (file) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      setPreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
      setPreview(reader.result);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    setTrack(event.target.files[0]);
    setTrackURL("");
    setUploadPercentage(0);
    event.target.value = null;
    await handleMetadata(file);
  };

  const handleMetadata = async (file) => {
    try {
      const metadata = await parseBlob(file);
      // setFormData({
      //   ...formData,
      //   ["isrc"]: metadata.common.isrc[0],
      //   ["artist"]: metadata.common.artist,
      // });
      const uint8Array = new Uint8Array(metadata?.common?.picture[0].data);
      const fileName = Math.floor(1000000000 + Math.random() * 9000000000); // desired file name
      const mimeType = "image/jpeg"; // desired MIME type, e.g., "image/jpeg" or "audio/mp3"

      const imgFile = createFileFromUint8Array(uint8Array, fileName, mimeType);
      handleMetadataImage(imgFile);
      handleImgUpload(imgFile);
    } catch (error) {
      console.error("Error parsing metadata:", error.message);
    }
  };

  function createFileFromUint8Array(uint8Array, fileName, mimeType) {
    // Create a Blob from the Uint8Array
    const blob = new Blob([uint8Array], { type: mimeType });

    // Create a File object from the Blob
    const file = new File([blob], fileName, {
      type: mimeType,
      lastModified: Date.now(),
    });

    return file;
  }

  const handleFileRemove = () => {
    setTrack(null);
    setUploadPercentage(0);
    setPreview("");
  };

  const handleDrop = (event) => {
    event.preventDefault();
    if (event.dataTransfer.files?.length > 1) {
      toast.error("Multiple file not allowed.", { duration: 1500 });
      return;
    }

    const audioFiles = Array.from(event.dataTransfer.files).filter((file) =>
      file.type.startsWith("audio/")
    );
    if (audioFiles.length > 0) {
      setUploadPercentage(0);
      setTrack(audioFiles[0]);
      setTrackURL("");
    } else {
      toast.error("Please upload audio file only.", { duration: 1500 });
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleUpload = async () => {
    try {
      const response = await axiosInstance.get(`/aws/get-upload-url`, {
        params: {
          filename: track.name,
          fileType: track.type,
        },
      });

      const { url, objectUrl } = response.data;

      // Step 2: Upload file to S3 using the pre-signed URL
      await axios.put(url, track, {
        headers: {
          "Content-Type": track.type, // Ensure the file type is sent correctly
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadPercentage(percentCompleted); // Update the upload percentage
        },
      });

      setTrackURL(objectUrl);

      setFormData({
        ...formData,
        ["track"]: objectUrl,
      });
      toast.success("File uploaded successfully!", { duration: 1500 });
    } catch (error) {
      toast.error("File upload failed!");
    }
  };

  const handleImgUpload = async (file) => {
    console.log(file.type);

    setLoading(true);
    try {
      const response = await axiosInstance.get(`/aws/get-upload-url`, {
        params: {
          filename: file.name,
          fileType: file.type,
        },
      });

      const { url, objectUrl } = response.data;
      await axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      setFormData({
        ...formData,
        ["img"]: objectUrl,
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Image upload failed!");
    }
  };

  useEffect(() => {
    setFormData({
      ...formData,
      ["permalink"]: generateLinkFromString(formData.title),
    });
  }, [formData.title]);

  useEffect(() => {
    setFormData({
      ...formData,
      ["tags"]: selected,
    });
  }, [selected]);

  console.log(formData);

  const handleSongUpload = () => {
    if (formData.track === "") {
      toast.error("Upload the track first");
    } else if (formData.img === "") {
      toast.error("Upload the song image.");
    } else if (formData.musicList.length <= 0) {
      toast.error("Music Set List Can't be empty.");
    } else if (
      formData.title === "" ||
      formData.genre === "" ||
      formData.desc === ""
    ) {
      toast.error("Title, Genre & Description is required.");
    } else {
      dispatch(uploadSong(formData));
    }
  };

  useEffect(() => {
    if (song.uploadStatus) {
      store.dispatch({ type: songConstant.SONG_RESET });
      navigate("/profile");
    }
  }, [song]);

  const musicSetList = JSON.parse(localStorage.getItem("musicSetList"));

  const handleMusicList = () => {
    if (music === "" || artist === "") {
      toast.error("Music and Artist name is required.", { duration: 1500 });
    } else {
      musicList.push({
        music: music,
        artist: artist,
        // id: Math.floor(100000 + Math.random() * 900000),
      });
      setMusicList(musicList);
      setFormData({
        ...formData,
        ["musicList"]: musicList,
      });
      setMusic("");
      setArtist("");
    }
  };

  const handleRemoveMusic = (music) => {
    const index = musicList.indexOf(music);
    if (index !== -1) {
      musicList.splice(index, 1);
    }
    setMusicList(musicList);
    setFormData({
      ...formData,
      ["musicList"]: musicList,
    });
  };

  return (
    <div>
      <div className=" bg-dark sticky top-0 z-50">
        <Navbar />
      </div>
      <div className="bg-globalBg  max-w-[1280px] mx-auto justify-between flex flex-col items-center text-white">
        <div className="flex flex-col h-full items-center py-6 m-2 rounded-xl bg-dark lg:w-11/12 md:w-full sm:w-full lg:px-10 md:px-8 sm:px-2">
          {/* <div className="flex justify-between items-center max-w-[980px] w-full mb-6">
            <div />

            <div className="flex gap-1">
              <BtnFilled
                text={"Draft"}
                onClick={() => {}}
                width={120}
                bgColor={"#fefefe"}
                textColor={"black"}
              />
              <BtnFilled
                text={"Publish"}
                onClick={() => {}}
                width={140}
                bgColor={"#6813f2"}
                textColor={"white"}
              />
            </div>
          </div> */}

          {/* UPLOAD FILES HERE */}

          <div className=" border border-secondarycom shadow-2xl rounded-lg lg:px-10 md:px-6 sm:px-2 pt-3 pb-12 w-full max-w-[980px]">
            <div className="mt-4 mb-12 ">
              <h1 className="text-[32px] font-semibold text-center">
                Upload Music
              </h1>
              <p className="text-[12px] text-center">
                Share your latest tracks and mixes with the world.
              </p>
            </div>

            <input
              onChange={(event) => handleFileUpload(event)}
              type="file"
              id="fileUpload"
              className="hidden"
              multiple={false}
              accept="audio/*" // This ensures only audio files are allowed
            />

            <label
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              htmlFor="fileUpload"
              style={{ backgroundColor: "rgb(167, 102, 255, 0.3)" }}
              className="border border-white border-dashed py-12 rounded-lg w-full  flex flex-col justify-center items-center gap-2  cursor-pointer"
            >
              {fileUpload}
              <p className="text-[14px]  text-white">Drag and drop</p>
              <p className="text-[14px] leading-[0px] text-white">or</p>

              <p className="text-[14px]  text-white"> Choose your track</p>
            </label>
            {track && (
              <div
                style={{
                  background: `linear-gradient(to right, #228B22 ${uploadPercentage}%, #6813f2 ${uploadPercentage}%)`,
                }}
                className="flex justify-between items-center rounded-full p-2 mt-4"
              >
                <div className="flex items-center gap-2 text-white">
                  {musicFileWhite}
                  <p className="text-[14px] font-semibold ">{track?.name}</p>
                </div>
                <div
                  className="cursor-pointer text-white hover:scale-90 hover:text-red-500"
                  onClick={() => handleFileRemove()}
                >
                  {closeCrossWhite}
                </div>
              </div>
            )}

            {track && (
              <div className="w-full flex justify-center items-center ">
                <BtnFilled
                  text={
                    uploadPercentage > 0 && uploadPercentage < 100
                      ? `Uploading ${uploadPercentage}%...`
                      : trackURL !== ""
                      ? "Uploaded!"
                      : uploadPercentage === 100 && trackURL === ""
                      ? "Processing..."
                      : "Upload"
                  }
                  onClick={() => handleUpload()}
                  width={220}
                  bgColor={
                    uploadPercentage === 100 && trackURL === ""
                      ? "#006400"
                      : "#0d8df8"
                  }
                  textColor={
                    uploadPercentage > 0 && uploadPercentage < 100
                      ? "black"
                      : "white"
                  }
                  style={{ marginTop: "18px" }}
                  isDisabled={
                    (uploadPercentage > 0 && uploadPercentage < 100) ||
                    trackURL !== "" ||
                    (uploadPercentage === 100 && trackURL === "")
                      ? true
                      : false
                  }
                  disabledColor={trackURL !== "" && "#006400"}
                />
              </div>
            )}
          </div>

          {/* UPLOAD DETAILS */}
          <div className="mt-12 border-b-[0.25px] border-b-gray-600 flex lg:gap-4 md:gap-4 sm:gap-1 ">
            {detailsBtnNames?.map((btn, index) => {
              return (
                <div
                  key={index}
                  className={
                    btn?.id === detailsBtnActiveNo
                      ? "flex justify-center items-center gap-1 border-b-2 border-b-primary px-2"
                      : "flex justify-center items-center gap-1 px-2"
                  }
                >
                  <button
                    key={btn?.id}
                    onClick={() => setdetailsBtnActiveNo(btn?.id)}
                    className={
                      btn?.id === detailsBtnActiveNo
                        ? "  text-primary lg:text-[18px] md:text-[16px] sm:text-[11px]"
                        : "  lg:text-[16px] md:text-[14px] sm:text-[10px] relative hover:text-[16px]  transition-all ease-in-out"
                    }
                    disabled={btn?.isDisabled}
                  >
                    {btn?.name}
                  </button>

                  {btn?.tag !== "" && (
                    <p className=" lg:text-[11px] md:text-[10px] sm:text-[9px] text-primarycom px-1 border border-primarycom rounded-md">
                      {btn?.tag}
                    </p>
                  )}
                </div>
              );
            })}
          </div>

          {detailsBtnActiveNo === 0 && (
            <div className="mt-12 ">
              <div className="w-full flex lg:flex-row md:flex-col-reverse sm:flex-col-reverse items-center justify-between gap-8">
                <div className="w-full lg:block md:hidden sm:hidden">
                  <UploadTextInputField
                    name={"title"}
                    label={"Title *"}
                    placeholder={"Name your track"}
                    value={formData?.title}
                    onChange={handleInputChange}
                    warning={isTitleWrote && formData?.title === ""}
                    warningText={"Please enter your track name."}
                  />

                  <UploadTextInputField
                    name={"permalink"}
                    label={"Permalink"}
                    secondaryLabel={`upvibez.com/${
                      generateLinkFromString(auth.user.name) || ""
                    }/`}
                    placeholder={"Your parmanent track link"}
                    value={formData?.permalink}
                    onChange={handleInputChange}
                    warning={isPermalinkAvailable && formData?.permalink === ""}
                    warningText={"Please enter your parmanent track link."}
                  />

                  <SelectInputCustom
                    label={"Genre *"}
                    name={"genre"}
                    onChange={handleInputChange}
                    value={formData?.genre}
                    options={genreOptions}
                  />
                </div>
                <div className="w-full flex flex-col justify-center items-center lg:hidden md:flex sm:flex">
                  <UploadTextInputField
                    name={"title"}
                    label={"Title *"}
                    placeholder={"Name your track"}
                    value={formData?.title}
                    onChange={handleInputChange}
                    warning={isTitleWrote && formData?.title === ""}
                    warningText={"Please enter your track name."}
                    width={280}
                  />

                  <UploadTextInputField
                    name={"permalink"}
                    label={"Permalink"}
                    secondaryLabel={`upvibez.com/${
                      generateLinkFromString(auth.user.name) || ""
                    }/`}
                    placeholder={"Your parmanent track link"}
                    value={formData?.permalink}
                    onChange={handleInputChange}
                    warning={isPermalinkAvailable && formData?.permalink === ""}
                    warningText={"Please enter your parmanent track link."}
                    width={280}
                  />

                  <SelectInputCustom
                    label={"Genre *"}
                    name={"genre"}
                    onChange={handleInputChange}
                    value={formData?.genre}
                    options={genreOptions}
                    width={280}
                  />
                </div>
                <div className="w-full flex justify-center items-center">
                  <div className=" mt-6 border rounded-lg overflow-hidden h-[232px] w-[232px] flex justify-center items-center cursor-pointer hover:scale-95 transition-all ease-in-out">
                    <label htmlFor="Upload" className="cursor-pointer">
                      {!previw ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-12"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                          />
                        </svg>
                      ) : (
                        <div
                          className={
                            loading
                              ? "h-[100px] w-[100px]"
                              : "h-[232px] w-[232px] border rounded-lg"
                          }
                          style={{
                            backgroundImage: loading
                              ? `url(${dot_gif})`
                              : `url(${previw})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                          }}
                        >
                          {!loading && (
                            <div className="h-[232px] w-[232px] hover:bg-customBgColor flex justify-center items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="size-12"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4.755 10.059a7.5 7.5 0 0 1 12.548-3.364l1.903 1.903h-3.183a.75.75 0 1 0 0 1.5h4.992a.75.75 0 0 0 .75-.75V4.356a.75.75 0 0 0-1.5 0v3.18l-1.9-1.9A9 9 0 0 0 3.306 9.67a.75.75 0 1 0 1.45.388Zm15.408 3.352a.75.75 0 0 0-.919.53 7.5 7.5 0 0 1-12.548 3.364l-1.902-1.903h3.183a.75.75 0 0 0 0-1.5H2.984a.75.75 0 0 0-.75.75v4.992a.75.75 0 0 0 1.5 0v-3.18l1.9 1.9a9 9 0 0 0 15.059-4.035.75.75 0 0 0-.53-.918Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </div>
                          )}
                        </div>
                      )}
                      <input
                        type="file"
                        id="Upload"
                        className="hidden"
                        onChange={(e) => {
                          handleImage(e);
                          handleImgUpload(e.target.files[0]);
                        }}
                        accept="image/*"
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div className="mb-3 mt-2 lg:w-full md:w-[280px] sm:w-[280px] mx-auto">
                <div className="text-[13px] font-medium text-gray-100">
                  <p className="font-bold ">Additional Tags</p>
                </div>
                <div className="text-[10px] font-medium text-gray-400 mb-2">
                  <p className="font-bold">
                    Add tags to describe the genre and mood of your track
                  </p>
                </div>
                <TagsInput
                  value={selected}
                  onChange={setSelected}
                  name="additionalTags"
                  placeHolder="Add tags"
                  classNames={{
                    input: "input-bg-dark",
                    tag: "tag-bg-dark",
                  }}
                />
              </div>

              <div className="mt-8 font-semibold mb-3 lg:ml-0 md:ml-5 sm:ml-5">
                Music Set List*
              </div>
              {musicList.length > 0 &&
                musicList.map((m, i) => (
                  <div key={m.id} className="flex items-center gap-4 mb-2">
                    <div>
                      {i + 1}. {m.music} - <strong>{m.artist}</strong>
                    </div>

                    <svg
                      onClick={() => handleRemoveMusic(m)}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="white"
                      className="size-6 p-1 cursor-pointer hover:scale-90 bg-red-500 rounded-full  transition-all ease-in-out"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18 18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                ))}
              <div className="mb-[24px] w-full flex lg:flex-row md:flex-col sm:flex-col items-center justify-between ">
                <UploadTextInputField
                  name={"title"}
                  label={"Music"}
                  placeholder={"Enter Music Name"}
                  value={music}
                  onChange={(e) => setMusic(e.target.value)}
                  width={280}
                />

                <UploadTextInputField
                  name={"title"}
                  label={"Artist"}
                  placeholder={"Enter Artist Name"}
                  value={artist}
                  onChange={(e) => setArtist(e.target.value)}
                  width={280}
                />

                <DivBtnFilled
                  text={"Add"}
                  onClick={() => handleMusicList()}
                  width={120}
                  bgColor={"#6813f2"}
                  textColor={"white"}
                  style={{ marginTop: "8px" }}
                />
              </div>

              <div className="lg:block md:hidden sm:hidden">
                <UploadTextAreaField
                  name={"desc"}
                  label={"Description *"}
                  placeholder={"Describe your track"}
                  value={formData?.desc}
                  onChange={handleInputChange}
                  // boxHeight={"200px"}
                  width={698}
                />

                <UploadTextAreaField
                  name={"caption"}
                  label={"Caption ⓘ"}
                  placeholder={"Add a caption to your post (optional)"}
                  value={formData?.caption}
                  onChange={handleInputChange}
                  boxHeight={"90px"}
                  width={698}
                />

                <RadioInputFieldWithSecondaryLabel
                  name={"privacyStatus"}
                  label={"Privacy:"}
                  placeholder={""}
                  value={formData?.privacyStatus}
                  onChange={handleInputChange}
                  style={{ marginBottom: 28 }}
                  options={privacyOptions}
                />
              </div>
              <div className="lg:hidden md:flex sm:flex flex-col justify-center items-center">
                <UploadTextAreaField
                  name={"desc"}
                  label={"Description *"}
                  placeholder={"Describe your track"}
                  value={formData?.desc}
                  onChange={handleInputChange}
                  // boxHeight={"200px"}
                  width={280}
                />

                <UploadTextAreaField
                  name={"caption"}
                  label={"Caption ⓘ"}
                  placeholder={"Add a caption to your post (optional)"}
                  value={formData?.caption}
                  onChange={handleInputChange}
                  boxHeight={"90px"}
                  width={280}
                />

                <RadioInputFieldWithSecondaryLabel
                  name={"privacyStatus"}
                  label={"Privacy:"}
                  placeholder={""}
                  value={formData?.privacyStatus}
                  onChange={handleInputChange}
                  style={{ marginBottom: 28 }}
                  options={privacyOptions}
                  width={280}
                />
              </div>

              <div className="flex gap-2 w-full justify-center">
                <BtnFilled
                  text={"Publish"}
                  onClick={() => handleSongUpload()}
                  width={320}
                  bgColor={"#6813f2"}
                  textColor={"white"}
                  style={{ marginTop: "18px" }}
                />
              </div>
            </div>
          )}

          {detailsBtnActiveNo === 1 && (
            <div className="mt-12 flex flex-col justify-center items-center">
              <div className="flex lg:flex-row md:flex-col sm:flex-col justify-center   items-center gap-3 ">
                <SelectInputCustom
                  label={"Contains Music"}
                  name={"containsMusic"}
                  onChange={handleInputChange}
                  value={formData?.containsMusic}
                  options={yesNoOptions}
                  width={280}
                />

                <UploadTextInputField
                  name={"artist"}
                  label={"Artist"}
                  placeholder={""}
                  value={formData?.artist}
                  onChange={handleInputChange}
                  width={280}
                />

                <UploadTextInputField
                  name={"publisher"}
                  label={"Publisher"}
                  placeholder={""}
                  value={formData?.publisher}
                  onChange={handleInputChange}
                  width={280}
                />
              </div>
              <div className="flex lg:flex-row md:flex-col sm:flex-col justify-center   items-center gap-3 ">
                <UploadTextInputField
                  name={"isrc"}
                  label={"ISRC"}
                  placeholder={""}
                  value={formData?.isrc}
                  onChange={handleInputChange}
                  width={280}
                />

                <UploadTextInputField
                  name={"composer"}
                  label={"Composer"}
                  placeholder={""}
                  value={formData?.composer}
                  onChange={handleInputChange}
                  width={280}
                />

                <UploadTextInputField
                  name={"releaseTitle"}
                  label={"Release Title"}
                  placeholder={""}
                  value={formData?.releaseTitle}
                  onChange={handleInputChange}
                  width={280}
                />
              </div>
              <div className="mt-10 w-full flex justify-center items-center">
                <div className="lg:block md:hidden sm:hidden">
                  <UploadTextInputField
                    name={"buyLink"}
                    label={"Buy Link"}
                    placeholder={""}
                    value={formData?.buyLink}
                    onChange={handleInputChange}
                    width={864}
                  />
                </div>
                <div className="lg:hidden md:block sm:block">
                  <UploadTextInputField
                    name={"buyLink"}
                    label={"Buy Link"}
                    placeholder={""}
                    value={formData?.buyLink}
                    onChange={handleInputChange}
                    width={280}
                    // style={{ width: "100%" }}
                  />
                </div>
              </div>
              <div className="w-full flex lg:flex-row md:flex-col sm:flex-col justify-center items-center gap-3">
                <UploadTextInputField
                  name={"albumTitle"}
                  label={"Album Title"}
                  placeholder={""} // Empty placeholder
                  value={formData?.albumTitle}
                  onChange={handleInputChange}
                  width={280}
                />
                <UploadTextInputField
                  name={"recordLabel"}
                  label={"Record Label"}
                  placeholder={""} // Empty placeholder
                  value={formData?.recordLabel}
                  onChange={handleInputChange}
                  width={280}
                />

                <UploadTextInputField
                  name={"releaseDate"}
                  label={"Release Date"}
                  placeholder={"DD/MM/YYYY"} // Empty placeholder
                  value={formData?.releaseDate}
                  onChange={handleInputChange}
                  width={280}
                />
              </div>
              <div className="flex lg:flex-row md:flex-col sm:flex-col justify-center items-center gap-3">
                <div className="lg:block md:hidden sm:hidden">
                  <UploadTextInputField
                    name={"barcode"}
                    label={"Barcode"}
                    placeholder={""} // Empty placeholder
                    value={formData?.barcode}
                    onChange={handleInputChange}
                    width={572}
                  />
                </div>
                <div className="lg:hidden md:block sm:block">
                  <UploadTextInputField
                    name={"barcode"}
                    label={"Barcode"}
                    placeholder={""} // Empty placeholder
                    value={formData?.barcode}
                    onChange={handleInputChange}
                    width={280}
                  />
                </div>

                <UploadTextInputField
                  name={"iswc"}
                  label={"ISWC"}
                  placeholder={"e.g. T-034.524.680-1"} // Empty placeholder
                  value={formData?.iswc}
                  onChange={handleInputChange}
                  width={280}
                />
              </div>
              <div className=" flex lg:flex-row md:flex-col sm:flex-col gap-3">
                <div className="lg:block md:hidden sm:hidden">
                  <UploadTextInputField
                    name={"pLine"}
                    label={"P Line"}
                    placeholder={"e.g. 2007 XYZ Record Company Limited"} // Empty placeholder
                    value={formData?.pLine}
                    onChange={handleInputChange}
                    width={572}
                  />
                </div>
                <div className="lg:hidden md:block sm:block">
                  <UploadTextInputField
                    name={"pLine"}
                    label={"P Line"}
                    placeholder={"e.g. 2007 XYZ Record Company Limited"} // Empty placeholder
                    value={formData?.pLine}
                    onChange={handleInputChange}
                    width={280}
                  />
                </div>

                <SelectInputCustom
                  name={"explicit"}
                  label={"Contains Explicit Content"}
                  value={formData?.explicit}
                  onChange={handleInputChange}
                  options={yesNoOptions}
                  width={280}
                />
              </div>
              <div className="lg:block md:hidden sm:hidden">
                <RadioInputFieldWithSecondaryLabel
                  name={"license"}
                  label={"License:"}
                  placeholder={""}
                  value={formData?.license}
                  onChange={handleInputChange}
                  style={{ marginBottom: 28 }}
                  options={licenseOptions}
                  isOptionsHorizontal={true}
                  width={864}
                />
              </div>
              <div className="lg:hidden md:block sm:block">
                <RadioInputFieldWithSecondaryLabel
                  name={"license"}
                  label={"License:"}
                  placeholder={""}
                  value={formData?.license}
                  onChange={handleInputChange}
                  style={{ marginBottom: 28 }}
                  options={licenseOptions}
                  isOptionsHorizontal={true}
                  width={280}
                />
              </div>
              <BtnFilled
                text={"Publish"}
                onClick={() => handleSongUpload()}
                width={320}
                bgColor={"#6813f2"}
                textColor={"white"}
                style={{ marginTop: "18px" }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="mt-0 bg-dark py-9 flex justify-center items-center w-full">
        <a href="" className="text-[11px] text-gray-500">
          This site is protected by reCAPTCHA and the Google Privacy Policy and
          Terms of Service apply.
        </a>
      </div>
    </div>
  );
};

export default Upload;
