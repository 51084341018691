import React from "react";
import { Link } from "react-router-dom";

import { icon_logo } from "../../assets/logo";

const Home2 = () => {
  return (
    <div className=" min-h-screen flex flex-col justify-center items-center text-white">
      <img src={icon_logo} alt="" height={110} width={110} />
      <div className="mt-2 font-bold text-[48px]">
        Home page is under development
      </div>
      <div className="uppercase text-[11px]">
        Try browsing profile page instead.
      </div>
      <Link
        to={"/profile"}
        className="mt-12 hover:scale-95 transition-all ease-in-out font-semibold text-black px-6 py-2 mx-auto rounded-full bg-white"
      >
        Profile
      </Link>
    </div>
  );
};

export default Home2;
